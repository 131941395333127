import Modal from './modules/modal.js'; //quite
import mobileMenu from './modules/mobile-menu.js'; //quite

//Touch sniffer
if ('ontouchstart' in document.documentElement) {
	document.querySelector('html').classList.add('is-touch');
}

// Init functions on pageload
function init() {
	mobileMenu();
	new Modal();
}

jQuery(document).ready(function ($) {
	init();

	function checkPosition() {
		let windowY = window.scrollY;
		if (windowY > 200) {
			document.body.classList.add('is-scroll');
		} else {
			document.body.classList.remove('is-scroll');
		}
	}

	function debounce(func, wait = 10, immediate = true) {
		let timeout;
		return function () {
			let context = this,
				args = arguments;
			let later = function () {
				timeout = null;
				if (!immediate) func.apply(context, args);
			};
			let callNow = immediate && !timeout;
			clearTimeout(timeout);
			timeout = setTimeout(later, wait);
			if (callNow) func.apply(context, args);
		};
	}

	window.addEventListener('scroll', debounce(checkPosition));

	// TABS

	const tabTriggers = document.querySelectorAll('.tabs-nav a');

	[...tabTriggers].forEach((trigger) => {
		trigger.addEventListener('click', switchTab);
	});

	function switchTab(e) {
		e.preventDefault();
		let elTarget = e.currentTarget.dataset.tab;

		removeActive('.tabs-nav a[data-active]');
		removeActive('.tab[data-active]');

		e.currentTarget.dataset.active = true;
		document.querySelector(`.tab[data-tab=${elTarget}]`).dataset.active = true;
	}

	function navTab(e) {
		e.preventDefault();

		const elNav = document.querySelector('.tabs-nav');
		const currentElNav = document.querySelector('.tabs-nav li[data-active]');
		const currentEl = document.querySelector('.tab[data-active]');

		const next = document.querySelector('.button-next');
		const prev = document.querySelector('.button-prev');

		let elTargetNav;
		let elTarget;

		if (e.currentTarget.classList.contains('button-next')) {
			elTargetNav = currentElNav.nextElementSibling;
			elTarget = currentEl.nextElementSibling;
		}

		if (e.currentTarget.classList.contains('button-prev')) {
			elTargetNav = currentElNav.previousElementSibling;
			elTarget = currentEl.previousElementSibling;
		}

		if (elNav.lastElementChild === elTargetNav) {
			next.classList.add('disabled');
		} else {
			next.classList.remove('disabled');
		}

		if (elNav.firstElementChild === elTargetNav) {
			prev.classList.add('disabled');
		} else {
			prev.classList.remove('disabled');
		}

		console.log(elNav.firstElementChild === elTargetNav);

		if (elTargetNav !== null) {
			removeActive('.tabs-nav li[data-active]');
			removeActive('.tab[data-active]');

			elTargetNav.dataset.active = true;
			elTarget.dataset.active = true;
		}
	}

	// document.querySelectorAll('.tabs-actions div').forEach((nav) => {
	// 	nav.addEventListener('click', navTab);
	// });

	const removeActive = function (selector) {
		[...document.querySelectorAll(selector)].forEach(
			(el) => delete el.dataset.active
		);
	};

	const accordionTriggers = document.querySelectorAll(
		'[data-list="accordion"] .item-trigger'
	);

	[...accordionTriggers].forEach((trigger) => {
		trigger.addEventListener('click', switchAccordion);
	});

	function switchAccordion(e) {
		e.preventDefault();

		if (e.currentTarget.parentNode.dataset.active === 'true') {
			delete e.currentTarget.parentNode.dataset.active;
		} else {
			e.currentTarget.parentNode.dataset.active = 'true';
		}
	}

	const swiperNav = new Swiper('.slider-nav', {
    // Optional parameters
    loop: true,
    slidesPerView: 'auto',
    spaceBetween: 20,
    allowTouchMove: false,

    // Navigation arrows
    navigation: {
      nextEl: '[data-section="slider"] .button-next',
      prevEl: '[data-section="slider"] .button-prev',
    }
  });

  const swiperContent = new Swiper('.slider-content', {
    // Optional parameters
    loop: true,
    spaceBetween: 20,
    allowTouchMove: false,

    // Navigation arrows
    navigation: {
      nextEl: '[data-section="slider"] .button-next',
      prevEl: '[data-section="slider"] .button-prev',
    }
  });

});
