const mobileMenu = () => {
	// Hamburger menu
	const $body = document.querySelector('body');
	const $hamburger = document.querySelector('.site-hamburger');
	const $close = document.querySelector('.site-nav .close');

	$hamburger &&
		$hamburger.addEventListener('click', () => {
			$body.classList.toggle('menu-is-open');
		});
	$close &&
		$close.addEventListener('click', () => {
			$body.classList.remove('menu-is-open');
		});
};

export default mobileMenu;
